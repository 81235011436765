<template>
  <b-row class="mx-1 mb-1">
    <b-col cols="12" sm="6" md="3" lg="3" xl="3">
      <span class="mr-1">Tipo de pago</span>
      <v-select
        @input="onPaymentTypeChange"
        :value="paymentTypeFilter"
        :dir="'ltr'"
        :options="paymentTypeOptions"
        :reduce="(option) => option.value"
        :clearable="false"
        class="invoice-filter-select"
        placeholder="Tipo de pago"
      >
        <template #selected-option="{ text }">
          <span class="text-truncate overflow-hidden">{{ text }}</span>
        </template>
        <template #option="{ text }">
          <span>{{ text }}</span>
        </template>
      </v-select>
    </b-col>
    <b-col cols="12" sm="6" md="3" lg="3" xl="3">
      <span class="mr-1">Entrega</span>
      <v-select
        @input="onDeliveryStatusChange"
        :value="deliveryStatusFilter"
        :dir="'ltr'"
        :options="deliveryStatusOptions"
        :reduce="(option) => option.value"
        :clearable="false"
        class="invoice-filter-select"
        placeholder="Estado de entrega"
      >
        <b-spinner v-if="loading" small label="Loading" />
        <template #selected-option="{ text }">
          <span class="text-truncate overflow-hidden">{{ text }}</span>
        </template>
        <template #option="{ text }">
          <span>{{ text }}</span>
        </template>
      </v-select>
    </b-col>
    <b-col cols="12" sm="6" md="3" lg="3" xl="3">
      <span class="mr-1">Estado</span>
      <v-select
        @input="onCancelationStatusChange"
        :value="cancelationStatusFilter"
        :dir="'ltr'"
        :options="cancelationStatusOptions"
        :reduce="(option) => option.value"
        :clearable="false"
        class="invoice-filter-select"
        placeholder="¿Está cancelada?"
      >
        <template #selected-option="{ text }">
          <span class="text-truncate overflow-hidden">{{ text }}</span>
        </template>
        <template #option="{ text }">
          <span>{{ text }}</span>
        </template>
      </v-select>
    </b-col>
    <b-col cols="12" sm="6" md="3" lg="3" xl="3">
      <span class="mr-1">Folio</span>
      <b-form-input
        id="v-folio"
        @input="$emit('update:folioFilter', $event)"
        variant="outline-primary"
        placeholder="1234"
        type="text"
      />
    </b-col>
  </b-row>
</template>

<script>
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
  },

  props: {
    paymentTypeFilter: {
      type: String,
      default: "",
    },
    deliveryStatusFilter: {
      type: String,
      default: "",
    },
    cancelationStatusFilter: {
      type: String,
      default: "",
    },
    folioFilter: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      paymentTypeOptions: [
        { value: "", text: "Todas" },
        { value: "cash", text: "Efectivo" },
        { value: "walleat", text: "Walleat" },
        { value: "bankcard", text: "Tarjeta bancaria" },
        { value: "bank_deposit", text: "Depósito/transferencia bancaria" },
        { value: "reward_points", text: "Puntos Walleat" },
      ],
      deliveryStatusOptions: [
        { value: "", text: "Todas" },
        { value: "assigning", text: "Por asignar" },
        { value: "assigned", text: "Despachador asignado" },
        {
          value: "rejected_by_dispatcher",
          text: "Rechazado por el despachador",
        },
        { value: "delivered", text: "Entregadas" },
      ],
      cancelationStatusOptions: [
        { value: "", text: "Todas" },
        { value: "true", text: "Canceladas" },
        { value: "false", text: "Activas" },
      ],
    };
  },
  methods: {
    onCancelationStatusChange(value) {
      this.$emit("update:cancelationStatusFilter", value);
    },
    onPaymentTypeChange(value) {
      this.$emit("update:paymentTypeFilter", value);
    },
    onDeliveryStatusChange(value) {
      this.$emit("update:deliveryStatusFilter", value);
    },
  },
};
</script>
